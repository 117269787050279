import { useCallback, useContext, useEffect, useState } from "react";
import { URLContext } from "../Context/URLContext";

export const useFetch = (collectionName='', Token, method='GET') => {
    const [data, setData] = useState(null);
    const [err, setErr] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { url } = useContext(URLContext);

    const Full_url = `${url}${collectionName}`;

    const fetchData = useCallback(async () => {
        const options = {
            method: method,
            headers: Token == null ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${Token}`,
            },
        };

        try {
            setIsLoading(true);
            const response = await fetch(Full_url, options);
            const json = await response.json();
            setData(json);
        } catch (err) {
            setErr(err);
        } finally {
            setIsLoading(false);
        }
    }, [Full_url, Token, method]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, err, isLoading, refetch: fetchData };
};
