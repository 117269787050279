import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { URLContext } from '../Context/URLContext';

const useCheckLoginStatus = () => {
    const [status, setStatus] = useState(false);
    const [user, setUser] = useState(null);
    const { url } = useContext(URLContext);

    const checkLoginStatus = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.get(`${url}status/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response => {
                setStatus(true);
                setUser(response.data.user);
            })
            .catch(error => {
                setStatus(false);
            });
        } else {
            setStatus(false);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    return { user, status, checkLoginStatus };
};

export default useCheckLoginStatus;
