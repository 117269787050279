import './Answers.css'
import { useEffect, useState } from 'react'
import { useParams, useLocation  } from "react-router-dom"
import { useFetch } from "../../hooks/UseFetch"
import PostForm from '../../hooks/PostForm'
import useEmail from '../../hooks/useEmail'
import React from 'react';

export default function Answer() {
    const { id } = useParams()
    const { data: questionsList } = useFetch(`questions/${id}/`, localStorage.getItem('token'))
    const { data: user } = useFetch(`category-author-detail/${id}`, null)
    const [formsDatas, setFormsDatas] = useState([])
    const { handleSubmit } = PostForm()
    const [formError, setFormError] = useState('')
    const { sendEmail } = useEmail()
    const location = useLocation()
    const nameProp = location.state?.data

    useEffect(() => {
        if (questionsList) {
            const initialFormsData = questionsList.map(question => ({ answer: "", question: question.id, author: nameProp.id}))
            setFormsDatas(initialFormsData)
        }
    }, [questionsList])

    const PostAnswer = async (e) => {
        try {
            for (const data of formsDatas) {
                if (data.answer === '' || data.question === '') {
                    throw new Error('fill the form');
                }
            }
            for (const data of formsDatas) {
                await handleSubmit(data, 'answer', '/sended/')
            }
            sendEmail(user.username, user.email, `${window.location.origin}/show-answer/${id}/`)
        } catch (error) {
            setFormError('please answer all questions')
        }
    }

    const handleChange = (e, index, id) => {
        setFormsDatas(prevFormsData => {
            const updatedFormsData = [...prevFormsData]
            updatedFormsData[index] = {
                ...updatedFormsData[index],
                answer: e.target.value,
                question: id
            }
            return updatedFormsData
        })
    }

    return (
        <div className="Answer-wrapper">
            <div className="category-title">
                answer To questions :
            </div>
            <div className='form-error'>
                {formError}
            </div>
            <div className="detail">
                {questionsList && questionsList.map((question, index) => (
                    <form key={index} className='answer' onSubmit={e => e.preventDefault()}>
                        <div>{question.question}</div>
                        <input className='answer-input' type="text" name="answer" value={formsDatas[index]?.answer || ""} onChange={e => handleChange(e, index, question.id)} />
                    </form>
                ))}
            </div>
            <div onClick={PostAnswer} className='btn btn-answer'>
                submit
            </div>
        </div>
    )
}
