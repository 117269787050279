import './ShowAnswers.css';
import { useParams, Link } from "react-router-dom";
import { useFetch } from '../hooks/UseFetch';
import React from 'react';

export function ShowAnswers() {
  const { id } = useParams();
  const { data: usernameList } = useFetch(`secret-user-list-view/${id}/`, localStorage.getItem('token'));
  const { data: category } = useFetch(`category-detail/${id}`, localStorage.getItem('token'));


  return (
    <div className="wrapper category-detail">
      <div className="category-title">
        {category && category.title} answers List:
      </div>
      <div className="questions-wrapper">
        {usernameList && [...usernameList].reverse().map((username, index) => (
          <div className="answer-list-wrapper" key={usernameList.length - 1 - index}>
            <span className="num">{usernameList.length - index}</span>
            <Link
              to={`/answer-detail/${id}?id=${username.id}`}
              className="title"
            >
              {username.name}
            </Link>
          </div>
        ))}
      </div>
      <Link to="/" className="btn">Home</Link>
    </div>
  );
}
