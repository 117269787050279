import { useState } from 'react';
import './CreateQuestion.css';
import { Link } from 'react-router-dom';
import { useFetch } from '../hooks/UseFetch';
import PostForm from '../hooks/PostForm'

export default function CreateQuestion() {
  const { data: categoryList } = useFetch('category-list', localStorage.getItem('token'));
  const { handleSubmit, isLoading, error } = PostForm();
  const [formData, setFormData] = useState({
    question: '',
    category: '', 
  });


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(formData, 'create-questions', `/category/${formData.category}/`); // Pass the question data to the hook
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="create-question-wrapper">
      <div className="login-form">
        <h1 className="title">Create Question</h1>
        <form onSubmit={handleFormSubmit}>
          <label className="name">
            <input type="text" placeholder="Question" required name="question" value={formData.question} onChange={handleChange} />
          </label>
          <label className="pass">
            {categoryList && (
              <select
                required
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option disabled value="">Category</option>
                {categoryList.map((category, index) => (
                  <option key={index} value={category.id}>{category.title}</option>
                ))}
              </select>
            )}
          </label>
          <div onClick={handleFormSubmit} className="btn" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send'}
          </div>
        </form>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
}
