import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLContext } from '../Context/URLContext';

export default function PostForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSucces, setIsSucces] = useState(false)
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { url } = useContext(URLContext);

  const handleSubmit = async (formData, collection, navigate_to, props_to='', setstatus='', refresh='') => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const token = localStorage.getItem('token'); // Ensure token is available
      const authorId = localStorage.getItem('author_id'); // Get author_id from localStorage

      // Add author_id to formData
      formData.author_id = authorId;

      // Convert formData to JSON string
      const jsonBody = JSON.stringify(formData);

      const response = await fetch(`${url}${collection}/`, {
        method: 'POST',
        headers: token != null ? {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        } : {
          'Content-Type': 'application/json',
        },
        body: jsonBody, // Use JSON string as body
      });

      if (!response.ok) {
        // Parse and throw detailed error message
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const data = await response.json();
      if (props_to !== '') {
        navigate(navigate_to, { state: { name: props_to, data: data } });
      } else {
        navigate(navigate_to, { state: { data: data } });
      }
      if (setstatus !== ''){
        setstatus(false)
      }
      if (refresh !== ''){
        refresh()
      }
      setIsSucces(true)

    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An unexpected error occurred'); // Set error state for UI display
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading, error, isSucces};
}
