import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home/Home';
import CreateCategory from './components/CreateCategory';
import { gapi } from "gapi-script";
import CategoryDetail from './components/CategoryDetail';
import CreateQuestions from './components/CreateQuestion'
import Answer from './pages/Answers/Answers';
import Send from './pages/MessageSend/Send';
import { ShowAnswers } from './components/ShowAnswers';
import AnswerDetail from './components/AnswerDetail';
import SetUesr from './components/SetUser';
import NotFound from './components/NotFound';

export default function App() {

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "*****.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/create-questions/" element={<CreateQuestions />}></Route>
        <Route path="/category/:id" element={<CategoryDetail />}></Route>
        <Route path="/answer/:id" element={<Answer />}></Route>
        <Route path="/sended/" element={<Send />}></Route>
        <Route path="/show-answer/:id" element={<ShowAnswers />}></Route>
        <Route path="/answer-detail/:id" element={<AnswerDetail />}></Route>
        <Route path="/set-user/:id" element={<SetUesr />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
