import './Home.css'
import Nav from '../../components/Nav'
import CheckLoginStatus from '../../components/CheckLoginStatus'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { URLContext } from '../../Context/URLContext'
import CategoryList from '../../components/CategoryList'
import CreateCategory from '../../components/CreateCategory'
import CreateQuestion from '../../components/CreateQuestion'
import Background from '../../components/Background'

export default function Home() {
  const { status, checkLoginStatus } = CheckLoginStatus()
  const { url } = useContext(URLContext)
  const [createCategoryStatus, setCreateCategoryStatus] = useState(false)
  const [createQuestionStatus, setCreateQuestionStatus] = useState(false)
  const [refreshPage, setRefreshPage] = useState(0)

  const refresher = () => {
    setRefreshPage(prevValue => prevValue + 1)
  }

  useEffect(() => {
  }, [refreshPage])

  useEffect(() => {
    const loadGoogleAPI = () => {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/api.js'
      script.onload = () => {
        window.gapi.load('auth2', () => {
          window.gapi.auth2.init({
            client_id: '956892197798-elm04dk13aqmd57n4ooj7sqp5i5p01it.apps.googleusercontent.com',
          })
        })
      }
      document.body.appendChild(script)
    }

    loadGoogleAPI()
  }, [])

  const handleGoogleLogin = async () => {
    try {
      const googleUser = await window.gapi.auth2.getAuthInstance().signIn()
      const idToken = googleUser.getAuthResponse().id_token

      const response = await axios.post(`${url}google-login/`, {
        access_token: idToken,
      })

      localStorage.setItem('token', response.data.token)
      checkLoginStatus()
    } catch (error) {
      alert('Login failed')
      checkLoginStatus()
    }
  }

  return (
    <>
      {!status && (
        <header>
          <div className='content'>
            <h2 className='title'>Answers to Your Questions</h2>
            <p>Your questions will be answered anonymously.</p>
            <div>
              You can ask your questions and request your friends to answer them anonymously.
            </div>
            <div className="btn" onClick={handleGoogleLogin}>Start</div>
          </div>
        </header>
      )}
      {status && (
        <>
          <Nav refresher={checkLoginStatus} />
          <CategoryList refreshPage={refreshPage} />
          <div className='create-categroy-question wrapper'>
            <div onClick={() => setCreateCategoryStatus(true)} className='btn create-category'>Create Category</div>
            <div onClick={() => setCreateQuestionStatus(true)} className='btn create-category'>Create Question</div>
          </div>

          {createQuestionStatus && <CreateQuestion setStatus={setCreateQuestionStatus} refresher={refresher} />}
          {createCategoryStatus && <CreateCategory setStatus={setCreateCategoryStatus} refresher={refresher} />}
          {createCategoryStatus && <Background setStatus={setCreateCategoryStatus} />}
          {createQuestionStatus && <Background setStatus={setCreateQuestionStatus} />}
        </>
      )}
    </>
  )
}
