import './Nav.css';

export default function Nav({refresher}) {

  return ( 
    <nav className='container wrapper'>
      <div className="right">
        <div to="/login/" className='logout' onClick={() => {
          localStorage.removeItem('token')
          refresher()
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 512 640" x="0px" y="0px"><title>l2</title><path d="M431,0H81A81,81,0,0,0,0,81V431a81,81,0,0,0,81,81H431a81,81,0,0,0,81-81V81A81,81,0,0,0,431,0ZM345.19,351.72c-0.15,14-7.26,23.81-20.48,28.44a14,14,0,0,1-4.53.73c-46.47,0-92.93,0-139.4.11-7.56,0-13.63-2.74-19-7.64a26,26,0,0,1-8.8-20c-0.06-32.34-.32-64.67-0.37-97s0.14-64.68.06-97c0-9.07,4-15.9,10.52-21.66,5.76-5.13,12.68-6.58,20.2-6.57,34.22,0,68.43,0,102.65-.08,10.29,0,20.58.19,30.87,0,7.64-.18,13.77,2.93,19.15,7.84A26.34,26.34,0,0,1,345.16,159q0,24.5,0,49c0,0.31-.06.62-0.15,1.42-6.16-5.31-12-10.28-17.72-15.37a4,4,0,0,1-.74-2.67c-0.24-10.29-.35-20.57-0.62-30.86-0.17-6.47-3.64-10.09-10-10.13q-40.3-.25-80.6-0.26c-17.47,0-34.95.18-52.42,0.35-6,.06-9.18,2.16-10.45,6.88a17.26,17.26,0,0,0-.42,4.36q-0.21,60.75-.36,121.51c0,22.54.22,45.07,0.36,67.61a17.64,17.64,0,0,0,.31,3.89,8.74,8.74,0,0,0,8.82,7c12,0.21,24,.41,36,0.42q47.53,0,95.05-.1c1.14,0,2.29,0,3.43-.1,6.74-.31,10.19-3.73,10.34-10.41q0.34-15.18.52-30.37a4.7,4.7,0,0,1,1.8-4c5-4.2,9.9-8.52,14.85-12.78,0.53-.45,1.09-0.86,2.08-1.63,0,4,0,7.61,0,11.16C345.25,326.57,345.32,339.15,345.19,351.72Zm11.11-92.66q-33.35,29-66.72,58a6.65,6.65,0,0,1-11-3.67,21.91,21.91,0,0,1-.43-4.38c-0.13-6.86-.2-13.72-0.29-20.58,0-1,0-2.43,0-3.43H220a9.75,9.75,0,0,1-9.45-9.49q0-19.11,0-38.21a10,10,0,0,1,10-10.17c18.21,0,36.42-.12,54.63-0.12H278c0-5-.06-9.63,0-14.34,0.07-4.57.16-9.07,0.49-13.62a6.1,6.1,0,0,1,4.92-5.7,5.06,5.06,0,0,1,5,1.11q27.67,24,55.41,47.88c4.57,4,9.07,8,13.59,12,0.6,0.53,1.17,1.11,1.95,1.84C358.27,257.19,357.32,258.17,356.3,259.06Z"/></svg>
        </div>
      </div>
      <div className="left">
        Wellcome, {JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).username} 
      </div>
    </nav>
  );
}
 