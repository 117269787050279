import { useParams } from 'react-router-dom';
import './SetUser.css';
import { useState } from 'react';
import PostForm from '../hooks/PostForm';
export default function SetUser() {
    const { id } = useParams(); // Extracts 'id' from URL parameters
    const [formData, setFormData] = useState({
        name: '',
        category: id, // Sets 'category' in formData to the 'id' from URL
    });

    const { handleSubmit } = PostForm(); // Retrieves handleSubmit function from PostForm hook

    const handlePost = async (e) => {
        e.preventDefault();
        handleSubmit(formData, 'secret-user-create', `/answer/${id}/`, formData.name);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,    
        });
    };

    return (
        <div className="set-user-wrapper">
            <form>
                <label>
                    <div>Select a name for yourself. This name will be displayed to your friend.</div>
                    <input
                        type="text"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Select a name....."
                    />
                </label>
                <button className='btn' onClick={handlePost}>Start</button>
            </form>
        </div>
    );
}
