// useEmail.js
import emailjs from 'emailjs-com';

const useEmail = () => {

  const sendEmail = async (userName, userEmail, address) => {

    const templateParams = {
      to_name: userName,       // Recipient's name
      to_email: userEmail,     // Recipient's email
      from_name: 'Secret Replies Team',  // Your name or your app's name
      subject: 'New Answer',    // Email subject
      link: address
    };

    try {
      const response = await emailjs.send(
        'service_obg29j4',  // Replace with your EmailJS service ID
        'template_0ssjxx2', // Replace with your EmailJS template ID
        templateParams,
        '_OKs4yt_f3w3o9b71' // Replace with your EmailJS Public Key
      );
      console.log('SUCCESS!', response.status, response.text);
    } catch (err) {
      console.log('FAILED...', err);
    }
  };

  return { sendEmail };
};

export default useEmail;
