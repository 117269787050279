import { createContext } from "react";

export const URLContext = createContext()

 
export const URLProvider = ({children}) => {

    return(
        <URLContext.Provider value={{url: 'https://secretreplies.pythonanywhere.com/'}}>
            {children}
        </URLContext.Provider>
    )    
}