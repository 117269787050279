import { useState } from 'react';
import PostForm from '../hooks/PostForm';

export default function CreateCategory({setStatus, refresher}){
    const [formData, setFormData] = useState({
        title: '',
        link : "https://nasirahmadahmady.com"
    })
    const {handleSubmit, isSucces} = PostForm()


    const handlePost = async (e) => {
        e.preventDefault(); 
        handleSubmit(formData, 'category-list', '/', '', setStatus, refresher)
    };  
     
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return(
        <>
            <div className="create-question-wrapper">
                <div className='login-form'>
                    <h1 className="title">Create Category</h1>
                    <form onSubmit={handlePost} >
                        <label className="name">
                            <input type="text" required name="title" value={formData.title} onChange={handleChange} placeholder="Category Title......... " />
                        </label>
                        <div className='btn' onClick={handlePost}>Create</div>
                    </form>
                </div>
            </div>
        </>
    )
}
