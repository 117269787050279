import './Sended.css'
import { Link } from 'react-router-dom'

export default function Send(){
    return (
        <div className="sended-wrapper">
            <p>Your answers has been sent.</p>
            <Link to="/" className='btn'>Create Your Questions</Link>
        </div>
    )
}