import './AnswerDetail.css'
import { useParams, Link, useLocation } from "react-router-dom";
import { useFetch } from '../hooks/UseFetch';
import React from 'react';

export default function AnswerDetail() {
  const { id } = useParams()
  const location = useLocation()
  const { data: answers_list } = useFetch(`questions/${id}/`, localStorage.getItem('token'));
  const { data: category } = useFetch(`category-detail/${id}`, localStorage.getItem('token'));  // Accessing name prop from location state
  const queryParams = new URLSearchParams(location.search);
  const authorName = queryParams.get('id');
  


  return (
    <div className="wrapper category-detail">
      <div className="category-title">
        {category && category.title} Questions answeres:
      </div>
      <div className="answers-wrapper">
        {answers_list && answers_list.length > 0 ? (
          answers_list.map((answer, ind) => (
            <React.Fragment key={ind}>
              <div className="question-answer">
                <div>
                  <div className="number">{ind+1}</div>
                  <div className="name">{answer.question}</div>
                </div>
                <div className="name answers">
                  {answer.answers.map((an, index) => (
                    <div key={index}>
                      {an.author == authorName ? an.answer : ''}
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div>No answers available</div>
        )}
      </div>
      <Link to="/" className="btn">Home</Link>
    </div>
  );
}
