import { useContext, useState } from 'react';
import {URLContext} from '../Context/URLContext'

const useDelete = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const {url} = useContext(URLContext)

    const deleteCategory = async (collection) => {
        const token = localStorage.getItem('token');

        if (!token) {
            setError('No token found');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${url}${collection}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData);
            } else {
                const responseData = await response.json();
                setData(responseData);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { deleteCategory, loading, error, data };
};

export default useDelete;
